// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/*-------------------------------------------------------------------------------------------------------------------
-------------------------------------------------------------------------------------------------------------------*/
/* dark */

:root {
  --ion-color-primary-gradient: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --ion-color-primary: #F15A24;
  --ion-color-dark: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --ion-color-dark-rgb: 241, 90, 36;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d44f20;
  --ion-color-dark-tint: #f26b3a;

  /* success */
  --ion-color-success: #2FDF75;
  --ion-color-success-20: #2FDF7533;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  /* warning */
  --ion-color-warning: #DEB000;
  --ion-color-warning-20: #DEB00033;
  --ion-color-warning-rgb: 222, 176, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c39b00;
  --ion-color-warning-tint: #e1b81a;

  /* danger o error */
  --ion-color-danger: #FD0021;
  --ion-color-danger-20: #FD002133;
  --ion-color-danger-rgb: 253, 0, 33;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #df001d;
  --ion-color-danger-tint: #fd1a37;


  /* alarm*/
  .ion-color-alarm {
   
    --ion-color-base: #FF9100;
    --ion-color-base-rgb: 255, 145, 0;
    --ion-color-contrast: #FFFFFF;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #E08100;
    --ion-color-tint: #FFA733;
    --ion-color-alarm-20: #FF910033;
  }
  --ion-color-alarm: #FF9100;
  --ion-color-alarm-20: #FF910033;
  /*grey*/
  .ion-color-grey {
    --ion-color-grey: #5c5c5c;
    --ion-color-grey-rgb: 92, 92, 92;
    --ion-color-grey-contrast: #ffffff;
    --ion-color-grey-contrast-rgb: 255, 255, 255;
    --ion-color-grey-shade: #515151;
    --ion-color-grey-tint: #6c6c6c;

  }


  /* information*/

  --ion-color-info: #0985E9;
  --ion-color-info-20: #0985E933;
  --ion-color-info-rgb: 9,
  133,
  233;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 0,
  0,
  0;
  --ion-color-info-shade: #0875cd;
  --ion-color-info-tint: #2291eb;

  /* information 20%*/
  --ion-color-info-20: #0985E933;
  --ion-color-info-20-rgb: 133,
  233,
  51;
  --ion-color-info-20-contrast: #ffffff;
  --ion-color-info-20-contrast-rgb: 0,
  0,
  0;
  --ion-color-info-20-shade: #75cd2d;
  --ion-color-info-20-tint: #91eb47;

  /*debug*/
  --ion-color-debug: #A200C4;
  --ion-color-debug-rgb: 162,
  0,
  196;
  --ion-color-debug-contrast: #ffffff;
  --ion-color-debug-contrast-rgb: 255,
  255,
  255;
  --ion-color-debug-shade: #8f00ac;
  --ion-color-debug-tint: #ab1aca;

  /*debug 20%*/
  --ion-color-debug-20: #A200C433;
  --ion-color-debug-20-rgb: 0,
  196,
  51;
  --ion-color-debug-20-contrast: #ffffff;
  --ion-color-debug-20-contrast-rgb: 0,
  0,
  0;
  --ion-color-debug-20-shade: #000000;
  --ion-color-debug-20-tint:#1a1a1a;


  /*log*/
  --ion-color-log: #00EBBC;
  --ion-color-log-rgb: 0,
  235,
  188;
  --ion-color-log-contrast: #ffffff;
  --ion-color-log-contrast-rgb: 0,
  0,
  0;
  --ion-color-log-shade: #00cfa5;
  --ion-color-log-tint: #1aedc3;

  /*log 20%*/
  --ion-color-log-20: #00EBBC33;
  --ion-color-log-20-rgb: 235,
  188,
  51;
  --ion-color-log-20-contrast: #ffffff;
  --ion-color-log-20-contrast-rgb: 0,
  0,
  0;
  --ion-color-log-20-shade: #cfa52d;
  --ion-color-log-20-tint: #edc347;

  /*generic*/

  --ion-color-generic: #C5C5C5;
  --ion-color-generic-rgb: 197,
  197,
  197;
  --ion-color-generic-contrast: #ffffff;
  --ion-color-generic-contrast-rgb: 0,
  0,
  0;
  --ion-color-generic-shade: #adadad;
  --ion-color-generic-tint: #cbcbcb;

  /*generic 20%*/

  --ion-color-generic-20: #C5C5C533;
  --ion-color-generic-20-rgb: 197,
  197,
  51;
  --ion-color-generic-20-contrast: #ffffff;
  --ion-color-generic-20-contrast-rgb: 0,
  0,
  0;
  --ion-color-generic-20-shade: #adad2d;
  --ion-color-generic-20-tint: #cbcb47;

  /* white e black*/
  /**************WHITE*******************/
  --white: #FFF;
  --white-80:rgba(255, 255, 255, 0.80);
  --white-70: rgba(255, 255, 255, 0.70);
  --white-50: rgba(255, 255, 255, 0.50);
  --white-20: rgba(255, 255, 255, 0.20);
  --white-10: rgba(255, 255, 255, 0.10);
  --white-0: rgba(255, 255, 255, 0.00);
  --ion-text-medium-dark:#5C5C5C;
  /****************BLACK*******************/

  --black: #1F1F1F;
  --black-70:#1F1F1FB2;
}

/*-------------------------------------------------------------------------------------------------------------------
    Classi aggiuntive per la selezione del colore
-------------------------------------------------------------------------------------------------------------------*/
.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.my-background-dark {
  background-color: var(--ion-color-primary-gradient);
}

.my-item-dark {
  --background: var(--ion-color-step-50);
}

.my-selected-dark {

  font-size: 13px;
}

ion-tab-button.tab-selected.my-selected-desktop-dark:hover {
  --color-selected: #858585;
  /* Cambia colore al passaggio del mouse */
  background: #141414;
}

ion-tab-button.tab-button-desktop-dark.my-selected-desktop-dark:hover {
  --color-selected: #858585;
  /* Cambia colore al passaggio del mouse */
  background: #141414;
}

.danger-text {
  color: var(--ion-color-danger);
}

/*-------------------------------------------------------------------------------------------------------------------
    Scale di colore per l'iOS
-------------------------------------------------------------------------------------------------------------------*/
/*dark*/
.ios body[color-theme='dark'] {



  --ion-background-color: #141414;
  --ion-background-color-rgb: 20, 20, 20;
  --ion-input-background: #292929;
  --ion-text-color: #FFF;
  --ion-text-color-rgb: 163, 163, 163;

  --ion-color-step-50: #1b1b1b;
  --ion-color-step-100: #222222;
  --ion-color-step-150: #292929;
  --ion-color-step-200: #313131;
  --ion-color-step-250: #383838;
  --ion-color-step-300: #3f3f3f;
  --ion-color-step-350: #464646;
  --ion-color-step-400: #4d4d4d;
  --ion-color-step-450: #545454;
  --ion-color-step-500: #5c5c5c;
  --ion-color-step-550: #636363;
  --ion-color-step-600: #6a6a6a;
  --ion-color-step-650: #717171;
  --ion-color-step-700: #787878;
  --ion-color-step-750: #7f7f7f;
  --ion-color-step-800: #868686;
  --ion-color-step-850: #8e8e8e;
  --ion-color-step-900: #959595;
  --ion-color-step-950: #9c9c9c;

  --ion-background-color-modal: #202428;
  --ion-background-segment-button: rgba(13, 13, 13, 1);
  --ion-item-background: transparent;
  --ion-toolbar-background: var(--ion-input-background);
  --ion-tab-bar-background: var(--ion-input-background);
  --ion-card-background: #29292999;
  --card-input: #292929;
  --card-input-60: rgba(41, 41, 41, 0.60);
  --segment-button-bg: #0A0A0A;
  --segment-button-bg-50: rgba(10, 10, 10, 0.50);
  --segment-button-bg-10: rgba(10, 10, 10, 0.10);
  --input-background: #292929;
  --ion-text-medium-dark: #5C5C5C;




}

/*-------------------------------------------------------------------------------------------------------------------
  Classi aggiuntive per iOS
-------------------------------------------------------------------------------------------------------------------*/
ion-modal.dark {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  --background: var(--ion-background-color);
  --background-opacity: 0.5;
  --backdrop-opacity: 70% !important;
}

/*-------------------------------------------------------------------------------------------------------------------
                                                               Classi segment
-------------------------------------------------------------------------------------------------------------------*/
ion-segment-button.dark {
  --background-checked: var(--ion-color-primary-gradient);

  --indicator-color: transparent;
  --color-checked: #ffffff;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;

  }

}

ion-segment.dark-transparent {
  color: rgb(255, 255, 255);
  --background: transparent;

}

ion-segment-button.panel-desk-tab-dark {
  --background-checked: white;
  --border: 2px solid white;
  --indicator-color: transparent;
  --color-checked: black;
  border: 2px solid;
  border-radius: 10px;
  height: 40px;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;

  }

}

ion-segment-button.panel-desk-tab-dark:hover {
  --background: #FFFFFF1A;
  /* Colore di sfondo al passaggio del mouse */
  --color: white;
  /* Colore del testo al passaggio del mouse */
  --background-checked: #FFFFFF1A;
  ;
  --color-checked: white;
}

ion-segment.dark-controls {
  color: white;
  box-shadow: -5px -10px 29px rgb(0 0 0 / 60%);
  --background: #00000075;
  // width: 100%;
  // margin: 0 auto
}

ion-segment-button.panel-dark {

  --indicator-color: rgb(255, 255, 255);
  --color-checked: #ffffff;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;

  }

}

.theme-text {
  color: var(--ion-text-color);
}

ion-segment-button.default-dark {
  --background-checked: var(--ion-color-primary-gradient);
  --border-radius: 8px;
  min-width: 80px;
  // margin-top: 5px;
  // margin-bottom: 7px;
  --indicator-color: transparent;
  --color-checked: #ffffff;


  ion-label {
    padding-left: 3px;
    color: white;
    padding-right: 3px;

  }

}

ion-segment.dark-default {
  color: white;
  background: black;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  border-radius: 0 !important;
  margin: 0 !important;
}

ion-segment-button.dark {
  --background-checked: var(--ion-color-primary-gradient);
  --border-radius: 8px;
  min-width: 80px;

  --indicator-color: transparent;
  --color-checked: #ffffff;


  ion-label {
    padding-left: 3px;
    color: white;
    padding-right: 3px;
    // color:var(--ion-color-text)
  }

}

ion-segment-button.dark-default {

  --background-checked: var(--ion-color-primary-gradient);
  --indicator-color: transparent;
  --color-checked: #ffffff;

  ion-label {
    padding-left: 3px;
    padding-right: 3px;
    color: var(--ion-color-text)
  }

}

ion-label {
  color: var(--ion-color-text)
}

ion-segment.dark {
  color: white;
  background: black;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  border-radius: 0 !important;
  margin: 0 !important;
}


/*-------------------------------------------------------------------------------------------------------------------
    Scale di colore per il material design
-------------------------------------------------------------------------------------------------------------------*/

/*dark*/
.md body[color-theme='dark'] {
  --ion-background-color: #141414;
  --ion-background-color-rgb: 20, 20, 20;
  --ion-input-background: #292929;
  --ion-text-color: #FFF;
  --ion-text-color-rgb: 163, 163, 163;

  --ion-color-step-50: #1b1b1b;
  --ion-color-step-100: #222222;
  --ion-color-step-150: #292929;
  --ion-color-step-200: #313131;
  --ion-color-step-250: #383838;
  --ion-color-step-300: #3f3f3f;
  --ion-color-step-350: #464646;
  --ion-color-step-400: #4d4d4d;
  --ion-color-step-450: #545454;
  --ion-color-step-500: #5c5c5c;
  --ion-color-step-550: #636363;
  --ion-color-step-600: #6a6a6a;
  --ion-color-step-650: #717171;
  --ion-color-step-700: #787878;
  --ion-color-step-750: #7f7f7f;
  --ion-color-step-800: #868686;
  --ion-color-step-850: #8e8e8e;
  --ion-color-step-900: #959595;
  --ion-color-step-950: #9c9c9c;

  --ion-background-color-modal: #202428;
  --ion-background-segment-button: rgba(13, 13, 13, 1);
  --ion-item-background: transparent;
  --ion-toolbar-background: var(--ion-input-background);
  --ion-tab-bar-background: var(--ion-background-color);
  --ion-card-background: #29292999;
  --card-input: #292929;
  --card-input-60: rgba(41, 41, 41, 0.60);
  --segment-button-bg: #0A0A0A;
  --segment-button-bg-50: rgba(10, 10, 10, 0.50);
  --segment-button-bg-10: rgba(10, 10, 10, 0.10);
  --input-background: #292929;

}


.unit-dark {
  display: flex;

  padding: 10px 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  opacity: 1;
}


.global-dark {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  opacity: 1;
}


.card-device-dark {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  opacity: 1;

}

.panel-card-device {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  opacity: 1;
}

.card-room {
  display: flex;
  height: 137px;
  max-height: 137px;
  padding: 16px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  opacity: 1;
}

.card-time-window {

  padding: 7px 11px;
  margin: 4px 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  opacity: 1;
}

.item-card {
  --background: var(--ion-card-background);
}

.img-dark {
  fill: var(--ion-color-dark);
}

.avatar-dark {
  filter: brightness(0) invert(1);


}

.avatar-inhibit {
  fill: #5c5c5c;
}

.disabled-avatar-dark {
  filter: brightness(0.5)
}

.imgBorder-dark {
  border-radius: 50%;
  border: 2px solid white;
}

.thumbnail-avatar-dark {
  border: 1px solid white;

  background: linear-gradient(162deg, rgb(255 255 255 / 20%) 11.66%, rgb(31 31 31 / 0%) 87.02%);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  fill: white;


}

.svg-dark {
  fill: white;
  border: 1px solid white;
}

.svg-no-border-dark {
  fill: white;

}

.room-avatar-dark {
  fill: var(--ion-text-color);
}

.avatar-inactive-dark {
  fill: white;
}


.avatar-active-dark {
  fill: #000000;
}

.avatar-gradient-dark {
  fill: -webkit-linear-gradient(var(--ion-color-primary), #ff0500);
}

.avatar-color-dark {
  fill: #ffffff;
  color: #fff;

}

//---------------colori dei testi ------------------//
ion-text.title-card-dark {
  color: white;
}

ion-text.description-card-dark {
  color: rgba(163, 163, 163, 1);
}

ion-text.subtitle-color-dark {
  color: #A3A3A3;
}

ion-text.section-header-title-dark {

  color: #ffffff;
}

ion-text.gradient {
  background: -webkit-linear-gradient(var(--ion-color-primary), #ff0500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

ion-text.color-dark {
  color: white;
}

//--------------- ------------------//



.avatar-card-dark {
  fill: var(--ion-color-dark);
}

.avatar-device-dark {
  fill: var(--ion-text-color);
}

.icon-widget-dark {
  background: var(--ion-color-step-800);
}



ion-header::part(native) {
  border-bottom: transparent;

  .gradient {
    --background: rgb(32, 36, 40);
    --background: #29292999;
  }


}

.gradient-bg {
  color: #000000;
  border-radius: 15px;
}

.accordion-bg-dark {
  background: rgb(32, 36, 40);
  background: #29292999;
}


ion-content::part(native) {
  background-color: black;
}

ion-content::part(scroll) {
  scrollbar-color: grey transparent;
}


ion-content::part(scroll)::-webkit-scrollbar {
  width: 8px;
}

ion-content::part(scroll)::-webkit-scrollbar-track {
  background: transparent;
}

ion-content::part(scroll)::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

ion-item {
  &.ion-input-color-dark {
    margin: 10px 0;
    --padding-start: 25px;
    background: var(--ion-input-background);
    box-shadow: 5px 5px 20px #00000029;
    border: 1px solid #242A2D;
    border-radius: 10px;
    opacity: 1;
  }

  &.error {
    margin: 10px 0;
    --padding-start: 25px;
    background: var(--ion-input-background);
    box-shadow: 5px 5px 20px #00000029;
    border: 1px solid red;

    border-radius: 10px;
    opacity: 1;
  }

  &.white-border-dark {
    margin: 10px 0;
    --padding-start: 25px;
    background: var(--ion-input-background);
    box-shadow: 5px 5px 20px #00000029;
    border: 1px solid rgba(255, 255, 255, 0.50);
    border-radius: 10px;
    opacity: 1;
  }

  &.no-border-dark {
    margin: 10px 0;
    --padding-start: 25px;
    background: var(--ion-input-background);
    box-shadow: 5px 5px 20px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

}

ion-text.dark-dark {

  color: #ffffff;
}

sub.error {
  color: red;

}

sub.success {
  color: #2FDF75;

}

ion-icon.white-text {
  color: rgb(255, 255, 255);
}

ion-icon.dark {
  color: #fff;
  ;
}

.searchbar-dark {
  --background: var(--black);
  padding-left: 16px;
  padding-right: 16px;
  --box-shadow: none;
  color: rgb(163, 163, 163);
  font-family: "Roboto-regular";
}

.searchbar-background-dark {
  border: 0.5px solid #FFF;
  // background:  #292929;
}

div.colorGrey {
  color: grey;
}

.textInputTimer {
  font-size: 13px;
  font-family: 'Roboto-regular';
  color: var(--ion-color-primary);
  text-align: center;
  /* Aggiunto per centrare il testo */
}

div.text-datetime-box-dark {
  width: 53px;
  height: 29px;
  background: #29292999;
  box-shadow: 16px 5px 27px #00000029;

  border-radius: 8px;
  // padding-top: 13px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.text-datetime-box-dark {
  width: 53px;
  height: 29px;
  background: #29292999;
  box-shadow: 16px 5px 27px #00000029;

  border-radius: 8px;
  // padding-top: 13px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.custom-ion-item {

  width: 100%;
}

.align-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
}

.custom-ion-text {
  display: flex;
  align-items: center;
  /* Centra il testo verticalmente nel suo contenitore */
}

hr.greyLine {
  height: 1px;
  border-width: 0;
  color: #acabab;
  background-color: #80808067;
}

sub.greyText {
  color: #80808067;
}

.menu-bg-dark {
  --background: var(--black);
}

ion-menu::part(container) {
  border-radius: 0 10px 10px 0;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.18);
}

.avatar-gradient-dark {
  fill: var(--ion-color-primary);
}

.color-digital-dro-dark {
  height: 110px;
  box-shadow: 0px 4px 20px 0px rgba(13, 13, 13, 0.10);
  background: var(--ion-input-background);
  border-radius: 10px;
  padding: 8px;
  align-items: center;
  /* Centra verticalmente il contenuto del box */
  justify-content: center;
  // text-align: center;
}




.technology-item-dark {
  background-color: var(--ion-input-background);
  box-shadow: 5px 15px 20px #00000029;
  border-radius: 10px;
  // height: 76px;
}

ion-toolbar.color-thumbnail-isTablet {
  background: linear-gradient(180deg, #23272B 0%, #29292999 100%) 0% 0% no-repeat padding-box;
}

ion-card.edit-dark {
  box-shadow: 0px 0px 40px #00000029;

}

//---------------------------------- bottoni--------------------------------
ion-button::part(native) {
  --background-activated: #23272b60;
  --background-hover: #23272b60;
  // --background-hover-opacity: #23272b60;
}

ion-button.selected-dark::part(native) {

  max-height: 30px;
  border-radius: 10px;
  background: #ffffff;
  border: solid 2px #ffffff;
  color: black;
}

ion-button.unselected-dark::part(native) {
  font-family: 'Roboto-medium';
  font-size: 16px;
  max-height: 30px;
  border-radius: 10px;
  color: #ffffff;
  border: solid 2px #ffffff;
  background: transparent;
}

ion-button.selected-large-dark::part(native) {
  border-radius: 10px;
  background: #ffffff;
  border: solid 1.5px #ffffff;
  color: black;
}

ion-button.unselected-large-dark::part(native) {
  border-radius: 10px;
  color: #ffffff;
  border: solid 1.5px #ffffff;
  background: transparent;
}

// classi bottoni automation
ion-button.controls-grey-dark::part(native) {
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.10);
  background: rgba(31, 31, 31, 0.20);
  box-shadow: 0px 4px 20px 0px vargba(13, 13, 13, 0.10);

}

ion-button.controls-solid-dark::part(native) {
  font-family: 'Roboto-medium';
  font-size: 16px;
  background: var(--ion-color-primary-gradient);
  border-radius: 10px;
  color: #ffffff;
}

ion-button.grey-controls-dark::part(native) {
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.10);
  background: rgba(0, 0, 0, 0.20);

  box-shadow: 0px 4px 20px 0px rgba(13, 13, 13, 0.10);

}

ion-button.grey-dark::part(native) {
  border-radius: 10px;
  border: 1.5px solid var(--White, #FFF);
  background: rgba(31, 31, 31, 0.20);

}

ion-button.grey-no-border-dark::part(native) {
  border-radius: 10px;

  background: #292929;

}

ion-button.black-dark::part(native) {
  font-family: 'Roboto-medium';
  font-size: 16px;
  border-radius: 10px;
  background: #292929;
  color: #ffffff;
}


ion-button.solid-dark::part(native) {
  font-family: 'Roboto-medium';
  font-size: 16px;
  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
  color: #ffffff;
}

ion-button.transparent-dark::part(native) {

  background: transparent;
  border-radius: 10px;
  color: #ffffff;
  border: 1px solid white;
}

ion-button.grey-outline-dark::part(native) {

  border-radius: 10px;
  border: 1px solid white;
  background: transparent;


}

ion-button.selectDay-dark::part(native) {
  background-color: white;
  color: black;
  font-family: 'Roboto-bold';
}

ion-button.selectDay-dark-dark::part(native) {
  background-color: white;
  color: black;
  font-family: 'Roboto-bold';
}

ion-button.unselectDay-dark::part(native) {
  background-color: transparent;
  color: white;
  font-family: 'Roboto-bold';
  border: 1px solid white;
}

ion-button.unselectDay-dark-dark::part(native) {
  background-color: transparent;
  color: white;
  font-family: 'Roboto-bold';
  border: 1px solid white;
}

///////////---------------------------------------------///////////////
///
/// comandi per lo switch dei device

.outer-circle-switch-dark {
  position: relative;
  margin: 25%;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  background: var(--ion-background-color);

}

.inner_circle_dark {
  background-image: var(--ion-color-primary-gradient);
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  z-index: -1;
  border-radius: inherit;
  -moz-box-shadow: 0 0 75px #e7973a;
  -webkit-box-shadow: 0 0 75px #8d3b0b;
  box-shadow: 0 0 75px #be3106;
}

.off_circle_dark {
  background-image: linear-gradient(180deg, rgb(161, 161, 161) 100%, rgba(126, 125, 125, 0.692) 0%);
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  z-index: -1;
  border-radius: inherit;
  -moz-box-shadow: 0 0 75px #000000;
  -webkit-box-shadow: 0 0 75px #000000;
  box-shadow: 0 0 55px #000000;
}

.switchOff-dark {
  color: #656463;
  border-radius: 25px;
  border: 2px solid '#grey';
  margin-left: 25%;
}




//----------------------------------------------------------------------
.item-status-bg-dark {
  border-radius: 10px;
  max-width: 411px;
  min-width: 80%;
  background: #292929;
}

.item-patrol-bg-dark {
  border-radius: 10px;
  max-width: 400px;
  min-width: 300px;
  background: var(--ion-background-segment-button);
}

.modal-header-bg-dark {
  background: var(--ion-background-color-modal)
}

.notifications-bg-dark {
  background-color: #292929;
}

.wrapperClass-dark {
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  background: var(--card-input-60);
  // border: 0.5px solid red;
}

.ellipsis-dark {
  fill: white;
}

//section header background
.background-dark {

  -webkit-filter: blur(15.5px);
}

.profile-gradient-dark {
  background: linear-gradient(to bottom, rgb(255 250 250 / 0%), rgb(0 0 0 / 97%))
}

.panelBackground-dark {
  background: linear-gradient(0deg, var(--black-70, rgba(31, 31, 31, 0.70)) 0%, var(--black-70, rgba(31, 31, 31, 0.70)) 100%);
  backdrop-filter: blur(3.5px);
  // background: var(--black-70, rgba(0, 0, 0, 0.7));
  // box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);

}

///////////--------------------Classi per svg-------------------------///////////////
svg-icon.grey-dark {
  fill: rgba(135, 135, 135, 1)
}

svg-icon.orange-dark {
  fill: rgba(241, 90, 36, 1)
}

svg-icon.white-dark {
  fill: rgba(31, 31, 31, 1);
}

svg-icon.default-dark {
  fill: rgb(255, 255, 255);

}

//-----------------------------fub button ---------------------------------------------------//


//----------------------------- FAB BUTTON ----------------------------------------------------//


ion-fab-button.blackFab-dark::part(native) {
  background: rgb(0, 0, 0);
  box-shadow: 0 0 30px #0000002F;
  color: rgb(255, 255, 255);


}

ion-fab-button.greyFab-dark::part(native) {
  background: #29292999;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 91%);


}

ion-fab-button.transparentFab-dark::part(native) {
  background: transparent;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

ion-fab-button.dangerFab-dark::part(native) {
  background: rgb(207, 9, 9);
  color: white;
  width: 40px;
  height: 40px;
}


ion-icon.move-dark {

  background: rgb(0, 0, 0);

}

ion-icon.grey-icon-dark {
  --fill: var(--ion-text-medium-dark);
  --color: var(--ion-text-medium-dark);
  ;
}

.selectedCard-dark {
  background: white;
  color: black;
}

.unselectedCard-dark {

  background: #29292999;
  color: white;

  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  --webkit-filter: blur(20px);
  opacity: 100% !important;



}

.weatherCard-dark {
  margin: 0;
  padding: 2.5px;
  width: 179px;
  height: 60px;
  background: #0D0D0D 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

//--------------header desktop-----------------------

.tab-button-desktop-dark {
  border-radius: 10px;
  background: var(--card-input);


  /* Effect/Ion-button */
  box-shadow: 0px 4px 20px 0px rgba(10, 10, 10, 0.10);
  padding: 0px;
  margin: 0 0 0 8px;
  height: 60px;
  max-width: 100px;
}


.fade-dark {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 15vh;
  background: #29292999
}

//stile degli item dei sensori

ion-item.styleItem {
  border-radius: 10px;

  margin-top: 10px;
}

//stile per lo unitAvatar

.background-blur-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 10%;
  z-index: -1;
}

hr.dark {
  height: 0.5px;
  border-width: 0;
  color: #ffffff;
  background-color: #f6f6f62b;

}