/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~animate.css/animate.min.css';
// swiper
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/effect-flip';
@import 'swiper/scss/scrollbar';
@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";
@import "swiper/scss/zoom";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@font-face {
  font-family: 'Roboto-black';
  src: url('./assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-black-italic';
  src: url('./assets/fonts/Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-bold-italic';
  src: url('./assets/fonts/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto-extra-bold';
  src: url('./assets/fonts/Roboto-ExtraBold.ttf');
}

@font-face {
  font-family: 'Roboto-extra-bold-italic';
  src: url('./assets/fonts/Roboto-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto-extra-light';
  src: url('./assets/fonts/Roboto-ExtraLight.ttf');
}

@font-face {
  font-family: 'Roboto-extra-light-italic';
  src: url('./assets/fonts/Roboto-ExtraLightItalic.ttf');
}



@font-face {
  font-family: 'Roboto-italic';
  src: url('./assets/fonts/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto-light';
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-light-italic';
  src: url('./assets/fonts/Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-medium-italic';
  src: url('./assets/fonts/Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-semibold';
  src: url('./assets/fonts/Roboto-SemiBold.ttf');
}

@font-face {
  font-family: 'Roboto-semibold-italic';
  src: url('./assets/fonts/Roboto-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto-thin';
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto-thin-italic';
  src: url('./assets/fonts/Roboto-ThinItalic.ttf');
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-lili);
}

.fab-swiper {
  --background: transparent;
  --box-shadow: none;
  --border-color: transparent;
}

/*-------------------------------------- TAB BAR -----------------------------------*/
ion-tab-bar.searchbar {
  --border: none;
  margin-bottom: 22px;
  padding-top: 10px;
  border-radius: 10px;
  --background: transparent !important;
}

ion-tab-bar.without-searchbar {
  --border: none;
  padding-bottom: 22px;
  padding-top: 10px;
  border-radius: 10px;
}

searchbar-card {
  &.sample {
    margin-bottom: 22px;
    margin-top: 24px;
  }

  &.inTabBar {
    margin-bottom: -20px;
  }
}

ion-tab-button {
  background-color: transparent
}

.addWidthSearchbar {
  width: 100%;
}

.reduceWidthSearchbar {
  width: 85%;
}

ion-toolbar.transparentBackground {
  --ion-toolbar-background: transparent;
  --border-color: transparent;
}

/*---------------------------------------------- GESTIONE TOOLTIP NEI TITLE ----------------------------*/
/* Nascondi il tooltip generato da Ionic */
[title] {
  pointer-events: auto;
  /* Mantieni clic e altri eventi */
  text-decoration: none;
}

[title]::after {
  display: none !important;
  /* Nascondi il tooltip */
}

[title]:hover::before {
  content: none !important;
}

/*-----------------------------------  AVATAR ------------------------------------------------*/
ion-avatar {
  padding: 0.5rem;
  margin: 0;
}




/*------------------------------------- ION PICKER ---------------------------------------------------*/

@media (min-width: 576px) {

  ion-picker {
    &.input-picker {
      &.picker-opt {
        color: #696666;

        &.picker-opt-selected {
          color: #f15a24;
        }
      }

      &.picker-button {
        color: #f15a24;

        &.ion-activated {
          color: #FFFFFF;
        }
      }

    }


    &.input-picker .picker-columns {
      display: flex;
      justify-content: center;
    }

    &.input-picker .picker-column {
      flex: 1 0 auto;
      /* Assicura che ogni colonna occupi solo lo spazio necessario */
      max-width: 50%;
      /* Opzionale, per controllare la larghezza massima di ogni colonna */
    }
  }
}

ion-picker {
  --ion-color-primary: rgba(241, 90, 36);
  --ion-item-background-activated: rgba(241, 90, 36);

  .input-picker .picker-columns {
    display: flex;
    justify-content: center;
  }
}

/*---------------------------------------------------------------------------------------------------*/

// -------------------------------- classi dei bottoni ----------------------------------------------//
ion-button {
  height: 55px;
}
ion-button.halfButton{
  width: 57px;
  }
  ion-button.fullButton{
    width: 130px;
  }
ion-button.buttonDays {
  width: 74px;
  height: 38px;
}

ion-button.grey::part(native) {
  background: #29292999;
  border-radius: 10px;
  color: var(--ion-text-color);

  box-shadow: 0 0 30px #0000002F;

}

ion-button.black::part(native) {

  border-radius: 10px;
  color: var(--ion-text-color);
  box-shadow: 0 0 30px #0000002F;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;

}


ion-button.solid::part(native)::after {
  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
}

ion-button.solid::part(native) {
  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 10px;
  color: #ffffff;

}

ion-button.transparent::part(native) {
  background: transparent;
  border-radius: 10px;
  color: #ffffff;
  border: 1px solid white;
}

ion-button.outline::part(native) {
  border-radius: 10px;
  color: #F15A24;
  box-shadow: 0 0 3px 0 rgb(241, 90, 36);
  border: solid 2px #F15A24;
  background: transparent;
  // background-color: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%), linear-gradient(180deg, #F15A24, #FF0500, );
  background-origin: border-box;
  background-clip: content-box, border-box;

}

// ---------------------classi aggiunte per i bottoni piccoli--------------------------------------------//
ion-button.solid-small::part(native) {
  background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  border-radius: 5px;
  color: #ffffff;
}

ion-button.outline-small::part(native) {
  border-radius: 5px;
  color: #F15A24;
  box-shadow: 0 0 3px 0 rgb(241, 90, 36);
  border: solid 2px #F15A24;
  background: transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;

}

ion-button.selectDay::part(native) {
  background-color: white;
  color: black;

}

ion-button.unselectDay::part(native) {
  background-color: transparent;
  color: white;

  border: 1px solid white;
}

ion-button.button-modal {
  margin-top: 24px;
  font-size: 16px;
}

// ---------------------------------------------------------------------------------------------
// --------------------------------- FAB BUTTON ------------------------------------------------------------

ion-fab-button::part(native) {
  background: rgba(237, 36, 0, 1);
  background: -moz-linear-gradient(180deg, var(---f15a24) 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
  background: -webkit-gradient(180deg, #F15A24 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
  background: -webkit-linear-gradient(180deg, #F15A24 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
  background: -o-linear-gradient(180deg, #F15A24 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
  background: -ms-linear-gradient(180deg, #F15A24 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
  background: linear-gradient(180deg, #F15A24 0%, #FF0500 100%) 0% 0% no-repeat padding-box;
}

ion-fab-button.blackFab::part(native) {
  background: rgb(0, 0, 0);
  box-shadow: 0 0 30px #0000002F;
  color: rgb(255, 255, 255);
}

ion-fab-button.greyFab::part(native) {
  background: -webkit-linear-gradient(#23272B, #29292999);
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  // box-shadow: 0px 0px 40px rgb(0 0 0 / 91%);
}

ion-fab-button.transparentFab::part(native) {
  background: transparent;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

ion-fab-button.dangerFab::part(native) {
  background: rgb(207, 9, 9);
  color: white;
  width: 40px;
  height: 40px;
}

//----------------------------------------------- TOGGLE ------------------------------------------------------
ion-toggle.color {
  --handle-background-checked: white;
  --background-checked: -webkit-linear-gradient(#f15a24, #ff0500);

}

/*-------------------------------------------------ION TEXT----------------------------------------------------------------*/
ion-text.gradient {
  background: -webkit-linear-gradient(#f15a24, #ff0500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-subtitle {
  --color: var(--white-70);
}

ion-text.selectDay {

  color: #F15A24;
}

ion-text.unselectDay {

  color: white;

}

ion-text.pop {
  color: var(--ion-color-info-shade);
}

ion-text.info {
  color: var(--ion-color-info)
}

.info {
  color: var(--ion-color-info)
}

.warning {
  color: var(--ion-color-alarm);
}

/*----------------------------------------------ION THUMBNAIL-------------------------------------------------*/

ion-thumbnail {
  background: linear-gradient(0deg, rgba(149, 149, 149, 1) 0%, rgba(59, 59, 59, 1) 100%);
}


/*----------------------------------------------ION THUMBNAIL-------------------------------------------------*/

.gridClass {
  margin: 8px;
}

/*----------------------------------------------------ION MODAL-----------------------------------------------------*/

// padding e margin da passare ai form nei componenti modal
.modal-layout {
  padding: 24px;
}

ion-modal {
  --border-radius: 16px;

}

ion-modal::part(backdrop) {
  opacity: 0.9;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.80);
}

.custom-modal {
  --height: 60%;
  --border-radius: 16px;
  padding: 16px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.header-backgroud-modal {
  background: var(--ion-background-color-modal);
}

/*********************------------CSS ALERT FUNZIONA SOLO SE MESSA IN GLOBAL---------------------------***/
ion-alert {
  &.danger-alert {
    --min-width: width: 273px !important;
    --min-height: 200px !important;
    border-radius: 14px;
    --background: linear-gradient(180deg, #FD0021 0%, #5D000C 100%) !important;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    color: white !important;
  }

  &.confirm-alert {
    --min-width: 200px !important;
    --min-height: 200px !important;
    --background: linear-gradient(180deg, #2FDF75 0%, #18703B 100%) !important;
    ;
    color: white !important;
  }
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 18px;

}

//titolo dell'alert
.alert-title.sc-ion-alert-md {
  // margin-top: 24px;
  font-size: 17px;
  text-align: center;
  font-family: 'Roboto-bold';
}

.alert-title.sc-ion-alert-ios {
  margin-top: 24px;
  font-size: 17px;
  font-family: 'Roboto-bold';
}

//messaggio dell'alert
.alert-message.sc-ion-alert-md {
  font-family: 'Roboto-regular';
  font-size: 13px;
  text-align: center;
}

//subtitle
.alert-sub-title.sc-ion-alert-md {
  font-size: 13px;
  text-align: center;
}

.alert-sub-title.sc-ion-alert-ios {
  text-align: center;
  font-family: 'Roboto-regular';
  font-size: 13px;
}

button.alert-button:nth-child(1) {
  color: rgba(241, 90, 36);
  text-transform: capitalize;
  font-weight: bold;
}

//bottone di destra
button.alert-button:nth-child(2) {
  color: rgba(241, 90, 36);
  // text-transform: capitalize;
  font-weight: bold;
}

button.alert-button:nth-child(3) {
  color: rgba(241, 90, 36);
  // text-transform: capitalize;
  font-weight: bold;

}

// gestione bottoni alert per ios
.alert-button.sc-ion-alert-ios {
  color: rgba(241, 90, 36);
  // text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  text-align: center;

}

.alert-button.button-alert-class {
  border-radius: 5px;
  height: 30px;
  color: rgb(255, 255, 255) !important;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  background-color: rgb(241, 90, 36);
  // margin: auto;
  margin-left: 25%;
  margin-right: 25%;
  /* Centra orizzontalmente */
  display: block;
  /* Assicurati che l'elemento sia un blocco per applicare il margine automatico */

}

.alert-icon-confirm {
  background: #334d38;

  ion-backdrop {
    opacity: 0.8;
    background: #23272B;
  }
}


.alert-icon-error {
  background: #3b1919;

  ion-backdrop {
    opacity: 0.8;
    background: #23272B;
  }
}

//---------------------------------fine alert -----------------------------------------------------------

.underline-button {
  --background: transparent;
  --border-style: none;
  --box-shadow: none;
  border: none;
  text-decoration: underline;
  // color: inherit;
  padding: 0;
  margin: 0;
  color: rgba(135, 135, 135, 1);
}

/*--------------------------------------------------- ION LIST---------------------------------------------*/
ion-list {
  background-color: transparent;
}

ion-list.input-padding {
  margin-left: 16px;
  margin-right: 16px;
}

.rowLine {
  border-bottom: 1px solid #676767
}

.card-list-padding {
  margin-left: 8px;
  margin-right: 8px;
}


/*----------------------------------------ION DATETIME-------------------------------------------------------------------*/
ion-datetime {
  --ion-color-primary: rgba(241, 90, 36);
  --ion-item-background-activated: rgba(241, 90, 36);

}

ion-datetime {
  --background: var(--ion-background-color);
  --background-rgb: var(--ion-background-color-rgb)
}

ion-datetime {
  --ion-color-secondary: rgba(73, 70, 69, 0.171);
}

.date-time-range-chooser {
  ion-backdrop {
    --backdrop-opacity: 1;
    background: #334d38;
  }

  .margin-panel-name {
    margin-left: 2%;
  }
}

/*----------------------------------------------------COLORE ICONE CARD TERMOSTATI-------------------------------------------------------*/

.avatar-idle {
  fill: grey;
  color: grey;
}

.avatar-heat {
  fill: red;
  color: red;
}

.avatar-cool {
  fill: rgb(56, 153, 209);
  color: rgb(56, 153, 209);
}

.avatar-power {
  fill: rgb(31, 179, 63);
  color: rgb(31, 179, 63);
}

.avatar-on {
  fill: var(--ion-color-primary);
  color: var(--ion-color-primary);
}

// margin per i bottoni nelle card che cambiano in base a dove si trovano



/***********************************---------ION CARD-------------------------------------------------------*/
ion-card.add-margin-for-panel {
  min-height: 233px;
}

/***********************************----------------------------------------------------------------*/

.add-margin-button-area-panel {
  margin-top: -7%;
}

.add-margin-button-scenario-panel {
  margin-top: 7%;
}

.add-margin-button-scenario-smartphone {
  margin-top: 38%;

}

/***********************************---------ION CARD-------------------------------------------------------*/
ion-card.add-margin-for-panel {
  min-height: 233px;
}

/***********************************----------------------------------------------------------------*/




//-------------- ----------------------------    CLASSI GLOBALI MARGIN E PADDING  ------------------------------*/

//------ IN PERCENTUALE ------
.margin-top-perc-4 {
  margin-top: 4%
}

.margin-top-perc-10 {
  margin-top: 10%
}

.margin-bottom-perc-30 {
  margin-bottom: 25%;
}

.margin-top-8 {
  margin-top: 8px;

}

.margin-top-10 {
  margin-top: 10px;
}

//------ IN PIXEL -------
.margin-top-16 {
  margin-top: 16px;
}

.margin-top-64 {
  margin-top: 64px;
}

.margin-top-bottom-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}


.margin-top-bottom-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-top-bottom-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.margin-top-bottom-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.margin-top-bottom-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.margin-top-bottom-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-top-bottom-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.margin-top-bottom-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.no-margin-left-right {
  margin-left: 0;
  margin-right: 0;
}
.margin-left-right-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.margin-left-right-6 {
  margin: 0px 6px 0px 6px;
}

.margin-left-right-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.margin-left-right-6 {
  margin: 0px 6px 0px 6px;
}

.margin-left-right-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.margin-left-right-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.margin-left-right-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.margin-top-6 {
  margin-top: 6px
}

.margin-top-8 {
  margin-top: 8px
}

.margin-top-20 {
  margin-top: 20px
}

.margin-top-24 {
  margin-top: 24px
}

.margin-top-28 {
  margin-top: 28px
}

.margin-top-30 {
  margin-top: 30px
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-48 {
  margin-top: 48px
}

.margin-top-80 {
  margin-top: 80px
}

.margin-left-16 {
  margin-left: 16px
}

.margin-left-4 {
  margin-left: 4px
}

.margin-left-8 {
  margin-left: 8px
}

.margin-left-22 {
  margin-left: 22px
}

.margin-right-0 {
  margin-right: 0
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.margin-bottom-16 {
  margin-bottom: 16px
}

.margin-bottom-24 {
  margin-bottom: 24px
}

.margin-bottom-26 {
  margin-bottom: 26px
}

.margin-bottom-48 {
  margin-bottom: 48px;
}

.padding-8 {
  padding: 8px
}

.padding-10 {
  padding: 10px;
}

.padding-left-4 {
  padding-left: 4px
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-30 {
  padding-left: 30px;

}

.padding-left-right-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.padding-top-4 {
  padding-top: 4px
}

.padding-top-8 {
  padding-top: 8px
}

.padding-top-12 {
  padding-top: 12px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-top-24 {
  padding-top: 24px;
}
.padding-bottom-4 {
  padding-bottom: 4px
}
.padding-bottom-8 {
  padding-bottom: 8px
}
.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.padding-left-right-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-top-bottom-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding-top-bottom-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-top-bottom-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-top-bottom-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-bottom-16 {
  padding-bottom: 16px
}

.padding-bottom-8 {
  padding-bottom: 8px
}

.padding-left-right-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.padding-left-right-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.padding-right-4 {
  padding-right: 4px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-8 {
  padding-right: 8px;
}

.padding-right-14 {
  padding-right: 14px;
}

.padding-right-16 {
  padding-right: 14px;
}

.padding-left-16 {
  padding-left: 14px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-top-bottom-0-left-right-16 {
  padding: 0 16px;
}

/************************************************* SEGMENT BUTTON ****************************/

ion-segment-button.notifications {
  --indicator-color: var(--ion-text-color);
  --color-checked: var(--ion-text-color);
  margin: 2px;
}

/****************************************** ION ITEM -------------------------------------------------------*/
ion-item.item-disabled {
  --color: var(--ion-text-medium-dark) !important;
  opacity: 1;
}

ion-item.item-disabled ion-label {
  color: var(--ion-text-medium-dark) !important;
  /* Cambia il colore del testo per i label */
  opacity: 1;
}

/*----------------------------------------------ION TOAST-----------------------------------------------------*/
ion-toast {
  --ion-color-primary: rgba(241, 90, 36);
  --ion-item-background-activated: rgba(241, 90, 36);

}

ion-toast.identify-toast {
  --background: linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: var(--ion-text-color);
}

ion-toast.identify-toast::part(message) {
  font-family: 'Roboto-regular';
  font-size: 16px;
}

/*-----------------------------------------------------ION BADGE-----------------------------------------------------------------*/
ion-badge.statusBadgeClass {
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  // modifiche fatte il 23/10/2024 in base al mockup
}

ion-badge.statusBadgeText {
  align-items: center;
  justify-content: center;
  padding: 5px 10px;

  border-radius: 200px;
  font-family: 'Roboto-regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}

.text-badge {
  font-family: 'Roboto-bold';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;

}

ion-badge.colorAlarm {
  background-color: var(--ion-color-alarm);
}

ion-badge.colorInfo {
  background-color: var(--ion-color-info);
}

ion-badge.colorWarning {
  background-color: var(--ion-color-warning);
}
ion-badge.colorAlarm {
  background-color: var(--ion-color-alarm);
}

ion-badge.colorLog {
  background-color: var(--ion-color-log);
}

ion-badge.colorSuccess {
  background-color: var(--ion-color-success);
}

ion-badge.colorPrimary {
  background-color: var(--ion-color-primary);
}

ion-badge.colorError {
  background-color: var(--ion-color-danger);
}

.badge-armed {
  border-color: #F15A24; // Bordo arancione
  color: #F15A24; // Testo arancione
}

.badge-disarmed {
  border-color: #A9A9A9; // Bordo grigio
  color: #A9A9A9; // Testo grigio
}

.statusBadgeText {
  border: 1px solid transparent; // Imposta un bordo di default trasparente
  background: transparent; // Imposta il background trasparente
  padding: 0.5rem 1rem; // Padding per rendere il badge più evidente
}

/*----------------------------------------------ION SEARCHBAR----------------------------------------------------------------------------*/

ion-searchbar.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  border-radius: 10px;

  box-shadow: 0px 4px 20px 0px rgba(41, 41, 41, 0.10);

}

/*------------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------- CLASSI PER LA SCROLLBAR ----------------------------------------------------------------------------*/

.mostly-customized-scrollbar {

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--ion-input-background);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ion-input-background);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--ion-input-background);
  }

  /* For Firefox */
  scrollbar-width: unset;
  scrollbar-color: var(--ion-input-background) var(--ion-input-background);
}

/* Optional: Additional styling for the ion-list to ensure scrollbar is visible */
ion-list.mostly-customized-scrollbar {
  max-height: 100vh;
  /* Adjust as needed */
  overflow-y: auto;
}

/* non eliminare, grazie a questo non abbiamo l'ombrina di sfondo negli header dektop*/
.header-md::after {
  background-image: none;
}

/*----------------------------------------------TYPOGRAPHY------------------------------------------------*/



//classe che manda a capo il testo senza mettere i puntini di spospensione
.wrap-text {
  white-space: normal;
  word-wrap: break-word;
}

.display-block {
  display: block;
}

//  H1
.h1-desktop {
  font-family: 'Roboto-bold';
  font-size: 35px;

}

.card-title-tablet {
  font-size: 1.5rem;
}

.card-subtitle-tablet {
  font-size: 1.5rem;
}

.text-underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}


// questa classe non esiste nella typo di vale

.large-text-light {
  font-family: 'Roboto-light';
  font-size: 40px;
}
